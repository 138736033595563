body {
  margin: 0;
  font-family: 'Avenir Next';
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.font-link {
  font-family: 'Avenir Next';
}

table thead, table tbody {
  font-size: small;
}

#cellMapName {
  font-size:large;
  font-weight: 100;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
.map-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0; 
  right: 0;
  width: 100%;
  /* z-index: 1; */
  /* width: 100%; */
  /* width: 400px;
  height: 700px; */

  /* display: inline-block; */
}
.mapboxgl-control-container {
  position: absolute;
  /* width: 100%; */
  /* height: 100%; */
  /* height: 100px; */
  top: 0;
  
}
.mapboxgl-ctrl mapboxgl-ctrl-group {
  height: 300px;
  width: 300px;
}
.mapboxgl-ctrl-top-right {
  height: 300px;
  width: 300px;
  z-index: 2;
  margin-top: 150px;
  padding: 10px;
}
.mapboxgl-ctrl mapboxgl-ctrl-group {
  position: absolute;
  /* pointer-events: none; */
  z-index: 2;
}
.mapboxgl-ctrl-geolocate mapboxgl-ctrl-geolocate-background {
  position: absolute;
  /* pointer-events: none; */
  z-index: 2;
}
.mapboxgl-ctrl-top-right button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon {
  min-width: 100%;
  width: 100%;
  background-image: url('./assets/Logo.svg');
  /* background-image: url('./assets/img/svg/mapboxgl-ctrl-zoom-out.svg') */
}
.mapboxgl-ctrl button.mapboxgl-ctrl-compass .mapboxgl-ctrl-icon{
  background-image: url('./assets/img/svg/mapboxgl-ctrl-compass.svg')
}
.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon{
  background-image: url('./assets/img/svg/mapboxgl-ctrl-zoom-in.svg')
}
.map-overlay {
  /* position: absolute;
  bottom: 0;
  right: 0;
  margin-right: 20px;
  overflow: auto;
  border-radius: 3px; */
  /* z-index:10; */
}
.collectCardImg {
  width: 100px;
  width: 100%;
  height: 175px;
}
.collectActiveCard {
  /* width: 65%;
  height: 70%; */
  height: 50vh;
  width: 35vh;
  /* position: absolute;     */
  margin:auto;
  margin-top: 15px;
  top: 15%;
  bottom:0;
  left:0;
  right:0;
}

.inventory {
  /* width: 200px; */
  /* right: 0;
  bottom: 0; */
  /* display: inline-block; */
}
#navBar {
  position: absolute;
}

.navbar {
  background-color: rgb(251, 249, 249);
  z-index: 10;
  position: relative;
}

.activeCardWrapper {
  margin-top: 50px;
  /* display: block; */
  /* position: relative; */
  display: inline-block;
}
.infoToast {
  display: inline-block;
  position: relative;
  padding: 10px;
}
.infoCardImg {
  outline-color: cornsilk;
  /* outline-style: solid; */
  width: 218px;
  height: 180px;
  object-fit: contain;
  
}
.guideGlossaryButtons {
  padding: 10px;
  margin: auto;
  /* width: 50%; */
  /* margin: 5px; */
  /* padding: 20px; */
}
.guideCardContainer {
  margin: 10px 10px 125px 10px;
}
/* was 150x150 */
.guideCard {
  width: 200px;
  height: 200px;
  /* box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); */
  /* margin: 20px; */
  /* background-color: rgb(199, 0, 0); */
}

.guideCardImg:hover {
  cursor: pointer;
  /* box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2); */
}
/* was 125x125 */
.guideCardImg {
  /* outline-color: cornsilk; */
  /* outline-style: solid; */
  width: 100%;
  /* max-width: 125px; */
  height: 125px;
  /* object-fit: contain; */
}
.guideCardFooter {
  align-items: top;
  vertical-align: top;
  bottom: 10px;
  height:5px;
  text-align: center;
  font-size: small;
  background-color: rgb(189, 37, 37);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.055);
}
.guideCardFooter p {
  margin: -11px;
}
.multiCardViewWrapper {
  position: relative;
}
.inventoryWrapper {
  /* width: 300px; */
  background-color: whitesmoke;
  position:absolute;
  left:0;
  bottom:0;
  right:0;
  /* z-index: 10; */
}
.inventoryButtonsGroup {
  /* position: relative; */
  /* display: flex; */
  justify-content: center; 
  align-items: center;
  padding: 5px;
}
.inventoryButton {
  margin: 0px 5px 0px 5px;
}
.toolKit {
  position: fixed;
  bottom: 9%;
  right: 0;
    padding: 10px;
}

.wrapper {
  /* display: inline-block; */
  /* z-index: 2; */
}